import React from "react"
import { StyledPopupButtonFull, StyledPopupContent } from "../../styled/Popup"
import { PopupSmall } from "../Popup/PopupSmall"
import { Checkbox } from "./DaysSelectPopup"
import { useShallowEffect } from "@mantine/hooks"

export const TimeSelectPopup = ({
  active,
  defaultValues,
  setActive,
  onSave,
}: {
  active: boolean
  defaultValues?: string[]
  setActive: (active: boolean) => void
  onSave: (selected: string[]) => void
}) => {
  const [checkedValues, setCheckedValues] = React.useState<{ [key: string]: boolean }>({})

  useShallowEffect(() => {
    if (defaultValues && active) {
      setCheckedValues({
        morning: defaultValues.includes("morning"),
        afternoon: defaultValues.includes("afternoon"),
        evening: defaultValues.includes("evening"),
        "whole-day": defaultValues.includes("whole day"),
      })
    }
  }, [defaultValues, active])

  return (
    <>
      <PopupSmall active={active} title="Select Time" setActive={setActive}>
        <StyledPopupContent>
          <div>
            {["morning", "afternoon", "evening", "whole day"].map(day => {
              const id = day.replace(/\s/g, "-").toLowerCase()

              return (
                <>
                  <Checkbox
                    key={`nl-checkbox-${id}`}
                    id={`nl-checkbox-${id}`}
                    name={day}
                    text={day}
                    checked={!!Object.entries(checkedValues).find(([key, value]) => key === id && value)}
                    onChange={() => {
                      setCheckedValues({
                        ...checkedValues,

                        // If "whole day" is checked, uncheck all other values
                        ...(id === "whole-day" &&
                          !checkedValues[id] && {
                            morning: false,
                            afternoon: false,
                            evening: false,
                          }),

                        // If other values is checked, uncheck "whole day"
                        ...(id !== "whole-day" &&
                          !checkedValues[id] && {
                            "whole-day": false,
                          }),

                        [id]: !checkedValues[id],
                      })
                    }}
                  />
                </>
              )
            })}
          </div>
        </StyledPopupContent>

        <br />

        <StyledPopupButtonFull
          onClick={() => {
            onSave(
              ["morning", "afternoon", "evening", "whole day"].filter(day => {
                const id = day.replace(/\s/g, "-").toLowerCase()
                return checkedValues[id]
              })
            )
            setActive(false)
          }}
          size={`large`}
          themeOverride={`secondary`}
          colour={`outline`}
          // disabled={Object.values(checkedValues).filter(value => value).length === 0}
        >
          Save
        </StyledPopupButtonFull>
      </PopupSmall>
    </>
  )
}
