import React from "react"

import { withPopup } from "./withPopup"
import { Outer, Overlay, InnerSmall, CloseSmall, ContentSmall, TitleContainerSmall } from "./Popup.styled"
import { Icon } from "../Icon/Icon"
import { StyledPopupSmallTitle } from "../../styled/Popup"

export const PopupSmall = withPopup(
  ({ title, active, full, setActive, children }: Props): JSX.Element => (
    <Outer active={active} full={full}>
      <Overlay onClick={() => setActive(false)} />
      <InnerSmall full={full}>
        <TitleContainerSmall>
          <CloseSmall type={`button`} onClick={() => setActive(false)}>
            <Icon name={"cross"} size={"s"} colour={"tertiary"} />
          </CloseSmall>
          <StyledPopupSmallTitle>{title}</StyledPopupSmallTitle>
        </TitleContainerSmall>
        <ContentSmall>{children}</ContentSmall>
      </InnerSmall>
    </Outer>
  )
)

export interface Props {
  title: string
  active?: boolean
  full?: boolean
  setActive: (active: boolean) => void
  children?: any
}
