import { Link } from "gatsby"
import tw, { css, styled } from "twin.macro"

import { P } from "../../styled/Text"

export const Label = styled.label`
  ${tw`flex items-center justify-center mb-12 relative cursor-pointer select-none`}
  ${({ alignLeft }) => alignLeft && tw`mb-5 last:mb-0 justify-start`}
`
export const Input = tw.input`absolute cursor-pointer opacity-0 h-0 w-0`
export const Content = styled(P)`
  ${tw`text-sm md:text-base`}
  ${({ bold }) => bold && tw`font-extrabold`}
`
export const StyledLink = tw(Link)`ml-1 text-pink hover:underline`
export const Marker = styled.span`
  ${tw`relative inset-0 h-5 w-5 mr-3 rounded-sm bg-transparent border-2 border-pink`}
  ${({ theme }) => theme === "secondary" && tw`border-green`}
  ${({ active, theme }) =>
    active &&
    css`
      &:after {
        content: "";
        position: absolute;
        left: 5px;
        top: 0px;
        width: 6px;
        height: 13px;
        border: solid ${theme === "secondary" ? "#48bfa8" : "#ff007d"};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    `}
`
