import React, { useEffect, useMemo, useState } from "react"
import { Step, StepsContainer } from "./Steps"
import { StepsButton } from "../../Steps/Steps.styled"
import { StyledContainer } from "../../../styled/Container"
import { navigate } from "gatsby"
import { ButtonsFlex, Center, Footer, Heading, InfoText, SubHeading, TextLight, TextLightHeading, WarningText, WarningTextFull } from "./Steps.styled"
import { Options } from "../../Options/Options"
import { Count } from "../../Pagination/Pagination.styled"
import { Icon } from "../../Icon/Icon"
import { AvailabilityData, NLFormAvailability } from "../../NLForm/NLFormAvailability"
import { TableConfirm } from "../../TableConfirm/TableConfirm"
import { useApp } from "../../../hooks/useApp"
import { withMentorEditAvailability } from "../../Mentor/withMentorEditAvailability"
import { useMediaQuery } from "@mantine/hooks"

const maxSteps = 3
const availabilityInitialData: AvailabilityData = {
  daysSelected: [],
  timeSelected: [],
  sessionTypeSelected: [],
}

export interface Props {
  handleChange: (value: number) => void
  handleDaysAvailableChange: (value: string[]) => void
  handleTimesAvailableChange: (value: string[]) => void
  handleSessionTypeChange: (value: string[]) => void
  handleSave: () => Promise<void>
  loading: boolean
}

export const Availability = withMentorEditAvailability(
  ({ handleChange, handleDaysAvailableChange, handleTimesAvailableChange, handleSessionTypeChange, handleSave, loading }: Props): JSX.Element => {
    const [step, setStep] = useState(0)
    const [isEdit, setIsEdit] = useState(false)
    const [hasChanges, setHasChanges] = useState(false)

    const { member } = useApp()

    const isLargeScreen = useMediaQuery("(min-width: 1024px)")

    const [sessionsPerMonth, setSessionsPerMonth] = useState<string | null>(null)
    const [availability, setAvailability] = useState<AvailabilityData>({
      daysSelected: [],
      timeSelected: [],
      sessionTypeSelected: [],
    })

    useEffect(() => {
      if (member?.membership?.daysAvailable && member?.membership?.daysAvailable.length > 0) {
        // Load member data and go to the confirmation page
        setSessionsPerMonth(member?.membership?.availability?.toString() || null)
        setAvailability({
          daysSelected: member?.membership?.daysAvailable || [],
          timeSelected: member?.membership?.timesAvailable || [],
          sessionTypeSelected: member?.membership?.sessionType || [],
        })
        setIsEdit(true)
        setStep(2)
      }
    }, [member?.membership?.daysAvailable])

    useEffect(() => {
      if (sessionsPerMonth !== null) {
        handleChange(Number(sessionsPerMonth))
      }
    }, [sessionsPerMonth])

    useEffect(() => {
      handleDaysAvailableChange(availability.daysSelected)
      handleTimesAvailableChange(availability.timeSelected)
      handleSessionTypeChange(availability.sessionTypeSelected)
    }, [availability])

    /**
     * If the user lands on the confirmation page, we want to set the edit mode to true.
     */
    useEffect(() => {
      if (step === 2) {
        setIsEdit(true)
      }
    }, [step])

    const isAvailabilityValid = useMemo(() => {
      return availability.daysSelected.length > 0 && availability.timeSelected.length > 0 && availability.sessionTypeSelected.length > 0
    }, [availability])

    const items = [
      {
        id: "0",
        title: "0",
        active: sessionsPerMonth === "0",
      },
      {
        id: "1",
        title: "1",
        active: sessionsPerMonth === "1",
      },
      {
        id: "2",
        title: "2",
        active: sessionsPerMonth === "2",
      },
      {
        id: "3",
        title: "3",
        active: sessionsPerMonth === "3",
      },
    ]

    const canGoBack = useMemo(() => {
      return step > 0
    }, [step])

    const confirmAvailabilityText = useMemo(() => {
      let days = "Every day"
      let time = "all day"

      switch (true) {
        case availability.daysSelected.some(d => ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays", "Saturdays", "Sundays"].includes(d)):
          days = `Weekly on ${availability.daysSelected.join(", ").replace(/,([^,]*)$/, " &$1")}`
          break
        case availability.daysSelected.includes("all weekends"):
          days = "Every weekend"
          break
        case availability.daysSelected.includes("all weekdays"):
          days = "Weekly on Monday-Friday"
          break
      }

      switch (true) {
        case availability.timeSelected.length > 1:
          time = `in the ${availability.timeSelected.join(", ").replace(/,([^,]*)$/, " &$1")}`
          break
        case availability.timeSelected.includes("morning"):
          time = "in the morning"
          break
        case availability.timeSelected.includes("afternoon"):
          time = "in the afternoon"
          break
        case availability.timeSelected.includes("evening"):
          time = "in the evening"
          break
      }

      return `${days} ${time}, ${availability.sessionTypeSelected[0]}`
    }, [availability])

    const noAvailability = useMemo(() => {
      const { daysSelected, timeSelected, sessionTypeSelected } = availability
      return daysSelected.length === 0 && timeSelected.length === 0 && sessionTypeSelected.length === 0
    }, [availability])

    return (
      <>
        <StepsContainer
          canGoBack={canGoBack}
          handleBack={() => {
            if (canGoBack) {
              setStep(step - 1)
            }
          }}
        >
          <Step active={step === 0}>
            <StyledContainer width="lg">
              <Heading>How many sessions would you like to give per month?</Heading>
              <SubHeading>You can edit the number anytime, even switching to 0 when life gets busy.</SubHeading>

              <Options
                size={isLargeScreen ? "medium" : "small"}
                required
                singleOnly={true}
                items={items}
                onClick={value => {
                  setHasChanges(true)
                  setSessionsPerMonth(value)
                }}
              />

              <Footer>
                {sessionsPerMonth === "0" && (
                  <WarningText>
                    <Icon name="alert" size="ml" /> This means no one can book you for a Mentor Hour until you edit your availability
                  </WarningText>
                )}
                {sessionsPerMonth !== null && sessionsPerMonth !== "0" && (
                  <InfoText>
                    You have selected to give <b>{sessionsPerMonth}</b> Mentor Hours each calendar month.
                  </InfoText>
                )}
                <StepsButton
                  colour={`dark`}
                  onClick={() => {
                    setStep(isEdit ? 2 : step + 1)
                  }}
                  size={`large`}
                  theme={`primary`}
                  disabled={sessionsPerMonth === null}
                >
                  {sessionsPerMonth === "0" ? "Turn off availability" : "Next"}
                </StepsButton>
                <Count>
                  Step {step + 1}/{maxSteps}
                </Count>
              </Footer>
            </StyledContainer>
          </Step>

          <Step active={step === 1}>
            <StyledContainer width="lg">
              <Heading>
                What's your general availability? <TextLightHeading>(Recommended)</TextLightHeading>
              </Heading>
              <SubHeading>This will show on your profile so mentee's know when you're available.</SubHeading>

              <Center>
                <NLFormAvailability
                  availability={availability}
                  onUpdate={data => {
                    setHasChanges(true)
                    setAvailability(data)
                  }}
                />
              </Center>

              <Footer>
                <ButtonsFlex>
                  <StepsButton
                    colour={`outline`}
                    onClick={() => {
                      setAvailability(availabilityInitialData)
                      setHasChanges(true)
                      setStep(step + 1)
                    }}
                    size={`large`}
                  >
                    Skip Step
                  </StepsButton>
                  <StepsButton
                    colour={`dark`}
                    onClick={() => {
                      setStep(step + 1)
                    }}
                    size={`large`}
                    disabled={!isAvailabilityValid}
                  >
                    Next
                  </StepsButton>
                </ButtonsFlex>
                <Count>
                  Step {step + 1}/{maxSteps}
                </Count>
              </Footer>
            </StyledContainer>
          </Step>

          <Step active={step === 2}>
            <StyledContainer width="lg">
              <Heading>{isEdit && !hasChanges ? "Edit your availability" : "Confirm your availability"}</Heading>
              <SubHeading></SubHeading>

              <Center>
                <TableConfirm
                  items={[
                    {
                      title: "Number of Sessions",
                      value: (
                        <>
                          {sessionsPerMonth} per month {sessionsPerMonth !== "0" ? <TextLight>(recurring each calendar month)</TextLight> : ""}
                        </>
                      ),
                      footer:
                        sessionsPerMonth === "0" ? (
                          <WarningTextFull>
                            <Icon name="alert" size="ml" /> This means no one can book you for a Mentor Hour until you edit your availability
                          </WarningTextFull>
                        ) : null,
                      onEdit: () => {
                        setStep(0)
                      },
                    },
                    {
                      title: "Preferred Time",
                      value: noAvailability ? "Not specified" : confirmAvailabilityText,
                      onEdit: () => {
                        setStep(1)
                      },
                    },
                    {
                      title: "Booking Method",
                      value: "Via email correspondence",
                    },
                    {
                      title: "Email",
                      value: member?.contact?.email || "",
                    },
                  ]}
                />
              </Center>

              <Footer>
                <StepsButton
                  colour={`dark`}
                  size={`large`}
                  loading={loading}
                  onClick={async () => {
                    await handleSave()
                    navigate("/account/profile")
                  }}
                >
                  Publish Availability
                </StepsButton>
                <Count>
                  Step {step + 1}/{maxSteps}
                </Count>
              </Footer>
            </StyledContainer>
          </Step>
        </StepsContainer>
      </>
    )
  }
)
