import React, { PropsWithChildren } from "react"
import { Section, Content } from "../../Steps/Steps.styled"
import { Header, HeaderButton, HeaderWrapper } from "./Steps.styled"
import { Icon } from "../../Icon/Icon"
import { navigate } from "gatsby"

export interface Props {
  canGoBack: boolean
  handleBack?: () => void
}

export const StepsContainer = ({ canGoBack, handleBack, children }: PropsWithChildren<Props>) => {
  const handleExit = () => {
    navigate("/account/profile")
  }

  return (
    <Section>
      <HeaderWrapper>
        <Header width="xl">
          <HeaderButton disabled={!canGoBack} onClick={handleBack}>
            <Icon colour={`grey-mid`} name={`chevronLeft`} size={`s`} />
          </HeaderButton>
          <div>Editing your Availability</div>
          <HeaderButton onClick={handleExit}>
            <Icon colour={`grey-mid`} name={`cross`} size={`s`} />
          </HeaderButton>
        </Header>
      </HeaderWrapper>

      <Content>{children}</Content>
    </Section>
  )
}

export const Step = ({ active, children }: PropsWithChildren<{ active: boolean }>) => {
  if (!active) return null

  return <>{children}</>
}
