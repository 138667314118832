import tw, { css, styled } from "twin.macro"

export const Wrapper = styled.div`
  ${tw`flex justify-center w-full bg-white border border-green rounded-2xl`}
`
export const Container = styled.div`
  ${tw`flex gap-3 flex-wrap items-center justify-center p-8 w-full md:w-200`}
`
export const TextWrapper = styled.div`
  ${tw`flex items-center justify-center gap-3`}
`
export const Text = styled.div`
  ${tw`text-lg leading-none text-grey-mid leading-loose`}
`
export const Select = styled.div`
  ${tw` text-lg leading-none text-grey-light border-b border-grey-light mt-1 pb-1 cursor-pointer`}
  ${({ active }) => active && tw`text-green border-green`}
`

export const DividerWithText = styled.div`
  ${tw`flex items-center my-5 -mx-2 text-grey-light font-normal`}
  ${css`
    &:before,
    &:after {
      flex: 1;
      content: "";
      padding: 1px;
      background-color: #eeedeb;
      margin: 10px;
    }
  `}
`
