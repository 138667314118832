import React from "react"
import { StyledPopupButtonFull, StyledPopupContent } from "../../styled/Popup"
import { PopupSmall } from "../Popup/PopupSmall"
import { Content, Input, Label, Marker } from "../Checkbox/Checkbox.styled"
import { DividerWithText } from "./NLForm.styled"
import { useShallowEffect } from "@mantine/hooks"

export const DaysSelectPopup = ({
  active,
  defaultValues,
  setActive,
  onSave,
}: {
  active: boolean
  defaultValues?: string[]
  setActive: (active: boolean) => void
  onSave: (selected: string[]) => void
}) => {
  const [checkedValues, setCheckedValues] = React.useState<{ [key: string]: boolean }>({})

  useShallowEffect(() => {
    if (defaultValues && active) {
      setCheckedValues({
        "all-weekdays": defaultValues.includes("all weekdays"),
        "all-weekends": defaultValues.includes("all weekends"),
        "all-days": defaultValues.includes("all days"),
        mondays: defaultValues.includes("Mondays"),
        tuesdays: defaultValues.includes("Tuesdays"),
        wednesdays: defaultValues.includes("Wednesdays"),
        thursdays: defaultValues.includes("Thursdays"),
        fridays: defaultValues.includes("Fridays"),
        saturdays: defaultValues.includes("Saturdays"),
        sundays: defaultValues.includes("Sundays"),
      })
    }
  }, [defaultValues, active])

  return (
    <>
      <PopupSmall active={active} title="Select Days" setActive={setActive}>
        <StyledPopupContent>
          <div>
            {["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays", "Saturdays", "Sundays"].map(day => {
              const id = day.replace(/\s/g, "-").toLowerCase()

              return (
                <>
                  <Checkbox
                    key={`nl-checkbox-${id}`}
                    id={`nl-checkbox-${id}`}
                    name={day}
                    text={day}
                    checked={!!Object.entries(checkedValues).find(([key, value]) => key === id && value)}
                    onChange={() => {
                      setCheckedValues({
                        ...checkedValues,

                        // If a value in this checkbox group is checked, uncheck all second checkbox group values
                        ...(!checkedValues[id] && {
                          "all-weekdays": false,
                          "all-weekends": false,
                          "all-days": false,
                        }),

                        [id]: !checkedValues[id],
                      })
                    }}
                  />
                </>
              )
            })}
          </div>

          <DividerWithText>OR</DividerWithText>

          <div>
            {["All weekdays", "All weekends", "All days"].map(day => {
              const id = day.replace(/\s/g, "-").toLowerCase()

              return (
                <>
                  <Checkbox
                    key={`nl-checkbox-${id}`}
                    id={`nl-checkbox-${id}`}
                    name={day}
                    text={day}
                    checked={!!Object.entries(checkedValues).find(([key, value]) => key === id && value)}
                    onChange={() => {
                      setCheckedValues({
                        ...checkedValues,

                        // If a value in this checkbox group is checked, uncheck all other values
                        ...(!checkedValues[id] && {
                          "all-weekdays": false,
                          "all-weekends": false,
                          "all-days": false,
                          mondays: false,
                          tuesdays: false,
                          wednesdays: false,
                          thursdays: false,
                          fridays: false,
                          saturdays: false,
                          sundays: false,
                        }),

                        [id]: !checkedValues[id],
                      })
                    }}
                  />
                </>
              )
            })}
          </div>
        </StyledPopupContent>

        <br />

        <StyledPopupButtonFull
          onClick={() => {
            onSave(
              [
                "Mondays",
                "Tuesdays",
                "Wednesdays",
                "Thursdays",
                "Fridays",
                "Saturdays",
                "Sundays",
                "all weekdays",
                "all weekends",
                "all days",
              ].filter(day => {
                const id = day.replace(/\s/g, "-").toLowerCase()
                return checkedValues[id]
              })
            )
            setActive(false)
          }}
          size={`large`}
          themeOverride={`secondary`}
          colour={`outline`}
          // disabled={Object.values(checkedValues).filter(value => value).length === 0}
        >
          Save
        </StyledPopupButtonFull>
      </PopupSmall>
    </>
  )
}

export const Checkbox = ({
  id,
  name,
  checked = false,
  text,
  onChange,
  ...props
}: {
  id: string
  name: string
  text: string
  checked?: boolean
  onChange?: () => void
}): JSX.Element => {
  return (
    <Label for={id} alignLeft={true}>
      <Input {...props} type={`checkbox`} id={id} checked={checked} name={name} onChange={onChange} />
      <Marker active={checked} theme={"secondary"} />
      <Content bold={checked}>{text}</Content>
    </Label>
  )
}
