import React, { useMemo } from "react"
import { Container, Select, Text, TextWrapper, Wrapper } from "./NLForm.styled"
import { Icon } from "../Icon/Icon"
import { DaysSelectPopup } from "./DaysSelectPopup"
import { TimeSelectPopup } from "./TimeSelectPopup"
import { SessionTypeSelectPopup } from "./SessionTypeSelectPopup"

export type AvailabilityData = {
  daysSelected: string[]
  timeSelected: string[]
  sessionTypeSelected: string[]
}

export const NLFormAvailability = ({ availability, onUpdate }: { availability: AvailabilityData; onUpdate: (data: AvailabilityData) => void }) => {
  const [daysSelect, setDaysSelect] = React.useState<boolean>(false)
  const [timeSelect, setTimeSelect] = React.useState<boolean>(false)
  const [sessionTypeSelect, setSessionTypeSelect] = React.useState<boolean>(false)

  const daysSelectedText = useMemo(() => {
    return availability.daysSelected.some(d => ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays", "Saturdays", "Sundays"].includes(d))
      ? availability.daysSelected.join(", ").replace(/,([^,]*)$/, " &$1")
      : availability.daysSelected[0]
  }, [availability.daysSelected])

  const timeSelectedText = useMemo(() => {
    return availability.timeSelected.join(", ").replace(/,([^,]*)$/, " &$1")
  }, [availability.timeSelected])

  const sessionTypeSelectedText = useMemo(() => {
    return availability.sessionTypeSelected.join(", ").replace(/,([^,]*)$/, " &$1")
  }, [availability.sessionTypeSelected])

  return (
    <>
      <Wrapper>
        <Container>
          <Text>I am generally available on</Text>
          <Select active={availability.daysSelected.length > 0} onClick={() => setDaysSelect(true)}>
            {availability.daysSelected.length > 0 ? daysSelectedText : "Mondays"}{" "}
            <Icon name={`chevronDown`} size={`s`} colour={availability.daysSelected.length > 0 ? "secondary" : `grey`} />
          </Select>
          <Text>in the</Text>
          <TextWrapper>
            <Select active={availability.timeSelected.length > 0} onClick={() => setTimeSelect(true)}>
              {availability.timeSelected.length > 0 ? timeSelectedText : "morning"}{" "}
              <Icon name={`chevronDown`} size={`s`} colour={availability.timeSelected.length > 0 ? "secondary" : `grey`} />
            </Select>
            <Text>, for</Text>
          </TextWrapper>
          <Select active={availability.sessionTypeSelected.length > 0} onClick={() => setSessionTypeSelect(true)}>
            {availability.sessionTypeSelected.length > 0 ? sessionTypeSelectedText : "online"}{" "}
            <Icon name={`chevronDown`} size={`s`} colour={availability.sessionTypeSelected.length > 0 ? "secondary" : `grey`} />
          </Select>
          <Text>sessions.</Text>
        </Container>
      </Wrapper>

      <DaysSelectPopup
        active={daysSelect}
        defaultValues={availability.daysSelected}
        setActive={active => setDaysSelect(active)}
        onSave={selected => onUpdate({ ...availability, daysSelected: selected })}
      />
      <TimeSelectPopup
        active={timeSelect}
        defaultValues={availability.timeSelected}
        setActive={active => setTimeSelect(active)}
        onSave={selected => onUpdate({ ...availability, timeSelected: selected })}
      />
      <SessionTypeSelectPopup
        active={sessionTypeSelect}
        defaultValues={availability.sessionTypeSelected}
        setActive={active => setSessionTypeSelect(active)}
        onSave={selected => onUpdate({ ...availability, sessionTypeSelected: selected })}
      />
    </>
  )
}
