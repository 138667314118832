import React from "react"
import { StyledPopupButtonFull, StyledPopupContent } from "../../styled/Popup"
import { PopupSmall } from "../Popup/PopupSmall"
import { Checkbox } from "./DaysSelectPopup"
import { useShallowEffect } from "@mantine/hooks"

export const SessionTypeSelectPopup = ({
  active,
  defaultValues,
  setActive,
  onSave,
}: {
  active: boolean
  defaultValues?: string[]
  setActive: (active: boolean) => void
  onSave: (selected: string[]) => void
}) => {
  const [checkedValues, setCheckedValues] = React.useState<{ [key: string]: boolean }>({})

  useShallowEffect(() => {
    if (defaultValues && active) {
      setCheckedValues({
        online: defaultValues.includes("online"),
        "in-person": defaultValues.includes("in-person"),
        "online-or-in-person": defaultValues.includes("online or in-person"),
      })
    }
  }, [defaultValues, active])

  return (
    <>
      <PopupSmall active={active} title="Select Session Type" setActive={setActive}>
        <StyledPopupContent>
          <div>
            {["online", "in-person", "online or in-person"].map(day => {
              const id = day.replace(/\s/g, "-").toLowerCase()

              return (
                <>
                  <Checkbox
                    key={`nl-checkbox-${id}`}
                    id={`nl-checkbox-${id}`}
                    name={day}
                    text={day}
                    checked={!!Object.entries(checkedValues).find(([key, value]) => key === id && value)}
                    onChange={() => {
                      setCheckedValues({
                        ...checkedValues,

                        // Uncheck all other values (radio input style)
                        ...(!checkedValues[id] && {
                          online: false,
                          "in-person": false,
                          "online-or-in-person": false,
                        }),

                        [id]: !checkedValues[id],
                      })
                    }}
                  />
                </>
              )
            })}
          </div>
        </StyledPopupContent>

        <br />

        <StyledPopupButtonFull
          onClick={() => {
            onSave(
              ["online", "in-person", "online or in-person"].filter(day => {
                const id = day.replace(/\s/g, "-").toLowerCase()
                return checkedValues[id]
              })
            )
            setActive(false)
          }}
          size={`large`}
          themeOverride={`secondary`}
          colour={`outline`}
          // disabled={Object.values(checkedValues).filter(value => value).length === 0}
        >
          Save
        </StyledPopupButtonFull>
      </PopupSmall>
    </>
  )
}
